@import '~node_modules/modern-normalize/modern-normalize.css';
@import './variables';
@import './mixins';

a {
  @include link-color($link-color);

  font-weight: 600;

  @media screen {
    &:visited {
      @include link-color($link-visited-color);
    }
  }
}
